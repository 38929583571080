import React from "react";
const __pages_import_0__ = React.lazy(() => import("/pages/audit.tsx"));
const __pages_import_1__ = React.lazy(() => import("/pages/export.tsx"));
const __pages_import_2__ = React.lazy(() => import("/pages/import.tsx"));
const __pages_import_3__ = React.lazy(() => import("/pages/importlog.tsx"));
const __pages_import_4__ = React.lazy(() => import("/pages/importlogs.tsx"));
import __pages_import_5__ from "/pages/index.tsx";
const __pages_import_6__ = React.lazy(() => import("/pages/loothistory.tsx"));
const __pages_import_7__ = React.lazy(() => import("/pages/player-add-edit.tsx"));
const __pages_import_8__ = React.lazy(() => import("/pages/players.tsx"));
const __pages_import_9__ = React.lazy(() => import("/pages/pointhistory.tsx"));
const __pages_import_10__ = React.lazy(() => import("/pages/profile.tsx"));
const __pages_import_11__ = React.lazy(() => import("/pages/settings.tsx"));
const __pages_import_12__ = React.lazy(() => import("/pages/user-add-edit.tsx"));
const __pages_import_13__ = React.lazy(() => import("/pages/users.tsx"));

const routes = [{"caseSensitive":false,"path":"audit","element":React.createElement(__pages_import_0__)},{"caseSensitive":false,"path":"export","element":React.createElement(__pages_import_1__)},{"caseSensitive":false,"path":"import","element":React.createElement(__pages_import_2__)},{"caseSensitive":false,"path":"importlog","element":React.createElement(__pages_import_3__)},{"caseSensitive":false,"path":"importlogs","element":React.createElement(__pages_import_4__)},{"caseSensitive":false,"path":"/","element":React.createElement(__pages_import_5__)},{"caseSensitive":false,"path":"loothistory","element":React.createElement(__pages_import_6__)},{"caseSensitive":false,"path":"player-add-edit","element":React.createElement(__pages_import_7__)},{"caseSensitive":false,"path":"players","element":React.createElement(__pages_import_8__)},{"caseSensitive":false,"path":"pointhistory","element":React.createElement(__pages_import_9__)},{"caseSensitive":false,"path":"profile","element":React.createElement(__pages_import_10__)},{"caseSensitive":false,"path":"settings","element":React.createElement(__pages_import_11__)},{"caseSensitive":false,"path":"user-add-edit","element":React.createElement(__pages_import_12__)},{"caseSensitive":false,"path":"users","element":React.createElement(__pages_import_13__)}];

export default routes;